<template>
  <div class="fees">
    <page-subnav class="fees__subnav">
      <ui-button
        class="fees__create-btn"
        @click="isFeeModalShown = true"
      >
        <span>{{ $t('CREATE_FEE_BTN') }}</span>

        <ui-icon
          class="fees__create-btn-icon"
          icon="plus"
        />
      </ui-button>

      <subnav-search
        v-if="template === FEES_LIST_TEMPLATES.users"
        class="fees__subnav-action fees__subnav-action_ml"
        :value="$route.query.search"
        @input="onSearch"
      />

      <template slot="right">
        <router-link
          class="fees__tab"
          active-class="fees__tab_active"
          :to="{
            params: { tab: FEES_URL_PARAM_TABS.general },
          }"
        >
          {{ $t('GENERAL_TAB') }}
        </router-link>

        <router-link
          class="fees__tab"
          active-class="fees__tab_active"
          :to="{
            params: { tab: FEES_URL_PARAM_TABS.users },
          }"
        >
          {{ $t('USERS_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <fees-list
      class="users__list"
      :list="list"
      :template="template"
      :load-more="loadMoreWrapper"
      @update-list-ask="loadList"
      @create-fee-click="isGeneralModalShown = true"
    />

    <fee-modal
      v-if="isFeeModalShown || isGeneralModalShown || selectedItem"
      class="fees__create-modal"
      :fee="selectedItem"
      :template="selectedItem || isGeneralModalShown ? template : undefined"
      @fee-updated="loadList"
      @close="closeFeeModal"
    />
  </div>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'

import FeesList from './components/FeesList'
import FeeModal from './components/FeeModal'

import { mapActions, mapGetters } from 'vuex'
import { feesActions, feesGetters } from './store/types'

import { FEES_LIST_TEMPLATES } from './constants'
import { FEES_URL_PARAM_TABS } from 'Constants/feesUrlParams'

import { enums } from '@shelf.network/js-sdk'
import { showError } from 'Utils/notifications'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

const TAB_TO_TEMPLATE_MAP = Object.freeze({
  [FEES_URL_PARAM_TABS.general]: FEES_LIST_TEMPLATES.general,
  [FEES_URL_PARAM_TABS.users]: FEES_LIST_TEMPLATES.users,
})

export default {
  name: 'fees',
  components: {
    UiButton,
    UiIcon,
    PageSubnav,
    SubnavSearch,

    FeesList,
    FeeModal
  },

  data () {
    return {
      isFeeModalShown: false,
      isGeneralModalShown: false,
      template: TAB_TO_TEMPLATE_MAP[FEES_URL_PARAM_TABS.general],

      FEES_URL_PARAM_TABS,
      FEES_LIST_TEMPLATES
    }
  },

  computed: {
    ...mapGetters('ui/fees', {
      list: feesGetters.LIST,
      isLoading: feesGetters.IS_LOADING,
      selectedItem: feesGetters.SELECTED_ITEM
    }),

    metaTitle () {
      const tabTitles = {
        [FEES_URL_PARAM_TABS.general]: this.$t('META_TITLE_GENERAL'),
        [FEES_URL_PARAM_TABS.users]: this.$t('META_TITLE_USERS'),
      }
      return tabTitles[this.$route.params.tab]
    },

    loadMoreWrapper () {
      return async () => {
        await this.loadMore(this.buildLoadListPayload())
      }
    }
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return
        this.loadList()
      }
    },
  },

  async created () {
    await this.loadDeparturePorts()
  },

  methods: {
    ...mapActions('ui/fees', {
      loadDeparturePorts: feesActions.LOAD_DEPARTURE_PORTS,
      loadListAction: feesActions.LOAD_LIST,
      loadMore: feesActions.LOAD_MORE,
      setIsLoading: feesActions.SET_IS_LOADING,
    }),

    async loadList () {
      try {
        await this.loadListAction(this.buildLoadListPayload())

        const tab = this.$route.params.tab
        this.template = TAB_TO_TEMPLATE_MAP[tab]
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    buildLoadListPayload () {
      const result = { filter: {} }
      const query = this.$route.query || {}

      result.filter.level = {
        [FEES_URL_PARAM_TABS.general]: enums.feeLevels.platform,
        [FEES_URL_PARAM_TABS.users]: enums.feeLevels.identity,
      }[this.$route.params.tab]

      if (query.search) {
        result.filter.search = query.search || ''
      }

      return result
    },

    closeFeeModal () {
      this.isFeeModalShown = false
      this.isGeneralModalShown = false
    },

    onSearch (value) {
      const querySearch = this.$route.query.search || ''
      const search = value.trim()

      if (search === querySearch) return

      this.setIsLoading(true)
      this.applySearchDebounced(search)
    },

    applySearchDebounced: debounce(async function (value) {
      this.$router.push({
        query: { search: value || undefined }
      })
      this.loadList()
    }, 500),
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.fees {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__create-btn {
    & /deep/ .ui-button__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-icon {
      display: block;
      margin-left: 1em;
      font-size: 1.2em;
    }
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "CREATE_FEE_BTN": "New fee",
    "GENERAL_TAB": "GENERAL",
    "USERS_TAB": "USERS",
    "META_TITLE_GENERAL": "General Fees",
    "META_TITLE_USERS": "Users Fees",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the fees list. Please try again later or contact the system owner."
  },
  "ka": {
    "CREATE_FEE_BTN": "ახალი ტარიფი",
    "GENERAL_TAB": "ᲖᲝᲒᲐᲓᲘ",
    "USERS_TAB": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲚᲔᲑᲘ",
    "META_TITLE_GENERAL": "ზოგადი ტარიფი",
    "META_TITLE_USERS": "სამომხმარებლო ტარიფი",
    "LIST_FETCH_FAILED_NOTIFY": "ტარიფების ცხრილი ვერ ჩაიტვირთა. კიდევ სცადეთ, ან დაუკავშირდით ადმინისტრატორს."
  },
  "ru": {
    "CREATE_FEE_BTN": "Новая",
    "GENERAL_TAB": "ОБЩИЕ",
    "USERS_TAB": "ПОЛЬЗОВАТЕЛИ",
    "META_TITLE_GENERAL": "Общие Комиссии",
    "META_TITLE_USERS": "Комиссии Пользователей",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список комиссий. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "CREATE_FEE_BTN": "Нова",
    "GENERAL_TAB": "ЗАГАЛЬНІ",
    "USERS_TAB": "КОРИСТУВАЧІ",
    "META_TITLE_GENERAL": "Загальні Комісії",
    "META_TITLE_USERS": "Комісії Користувачів",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список комісій. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
