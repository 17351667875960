<template>
  <div class="fee-item">
    <div class="fees-cmn__row">
      <p class="fee-item__cell">
        <fee-user-cell
          v-if="template === FEES_LIST_TEMPLATES.users"
          :fee="item"
        />

        <template v-else>
          {{ $t('GENERAL_FEE_MSG') }}
        </template>
      </p>

      <div class="fee-item__cell">
        <p
          v-for="location in destinationLocations"
          :key="location.country"
        >
          <ui-icon icon="pin" />
          <span>{{ formatLocation(location) }}</span>
        </p>
      </div>

      <div
        v-for="(port, i) in departurePorts"
        :key="i"
        class="fee-item__cell"
      >
        <p
          v-for="{ country } in destinationLocations"
          :key="country"
        >
          <template v-if="groupedFees[country][port.id]">
            {{ $fc(
              groupedFees[country][port.id].amount,
              groupedFees[country][port.id].currency
            ) }}
          </template>

          <template v-else>
            {{ $t('COMMON.SYMBOLS.MDASH') }}
          </template>
        </p>
      </div>

      <button
        class="fee-item__btn"
        @click="setSelectedItemId(item.id)"
      >
        <ui-icon
          class="fee-item__btn-icon"
          icon="pen-fill"
        />
      </button>
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@shelf.network/ui-kit'
import FeeUserCell from './FeeUserCell'

import { FEES_LIST_TEMPLATES } from '../constants'
import { TransportationFee } from 'Models/TransportationFee'

import { mapActions, mapGetters } from 'vuex'
import { feesActions, feesGetters } from '../store/types'

import { formatLocation } from 'Utils/formatLocation'

import groupBy from 'lodash/groupBy'
import uniqBy from 'lodash/uniqBy'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

export default {
  name: 'fee-item',
  components: {
    UiIcon,
    FeeUserCell,
  },

  props: {
    template: {
      type: String,
      default: FEES_LIST_TEMPLATES.general,
      validator (value) {
        return Object.values(FEES_LIST_TEMPLATES).includes(value)
      },
    },

    item: {
      type: TransportationFee,
      required: true,
    },
  },

  data () {
    return {
      FEES_LIST_TEMPLATES,
    }
  },

  computed: {
    ...mapGetters('ui/fees', {
      departurePorts: feesGetters.DEPARTURE_PORTS
    }),

    groupedFees () {
      return mapValues(
        groupBy(this.item.containerFees, 'destinationCountry'),
        val => keyBy(val, 'departurePort')
      )
    },

    destinationLocations () {
      return uniqBy(this.item.containerFees, 'destinationCountry')
        .map(item => ({
          country: item.destinationCountry,
          city: item.destinationPort,
        }))
    }
  },

  methods: {
    ...mapActions('ui/fees', {
      setSelectedItemId: feesActions.SET_SELECTED_ITEM_ID
    }),

    formatLocation
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/fees.scss";

.fee-item {
  padding: 2.1em 0;
  border-bottom: 1px solid rgba($color-grey, 0.5);

  &__cell {
    line-height: 2;

    &_end {
      justify-self: end;
    }
  }

  &__btn {
    background: $color-light-grey;
    border: none;
    text-decoration: none;
    text-align: center;
    color: $color-ui-default;
    width: 3.55em;
    height: 3.55em;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all 180ms;

    &-icon {
      display: block;
      color: $color-ui-secondary;
      font-size: 1.2em;
      padding-top: 0.15em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "GENERAL_FEE_MSG": "General fee"
  },
  "ka": {
    "GENERAL_FEE_MSG": "ზოგადი ტარიფი"
  },
  "ru": {
    "GENERAL_FEE_MSG": "Общая комиссия"
  },
  "uk": {
    "GENERAL_FEE_MSG": "Загальна комісія"
  }
}
</i18n>
