var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fee-user-cell cells-cmn__cell cells-cmn__btn"},[_c('lazy-ava-img',{staticClass:"cells-cmn__ava",attrs:{"title":_vm.fee.identityFullName,"src":_vm.fee.identityAvatarLink}}),_vm._v(" "),_c(_vm.$can(_vm.$USER_CLAIMS.EXTENSION_DEALERS) ? 'router-link' : 'div',{tag:"component",staticClass:"cells-cmn__str cells-cmn__str_nb",attrs:{"to":{
      name: 'extension.copart.dealers.entry',
      params: { dealerId: _vm.fee.identityId }
    }}},[_c('p',{staticClass:"cells-cmn__str cells-cmn__str_nb",attrs:{"title":_vm.fee.identityFullName}},[_vm._v("\n      "+_vm._s(_vm.fee.identityFullName)+"\n    ")]),_vm._v(" "),_c('p',{class:[
        'fee-user-cell__email',
        'cells-cmn__str',
        'cells-cmn__str_nb',
        'cells-cmn__str_sec'
      ],attrs:{"title":_vm.fee.identityEmail}},[_vm._v("\n      "+_vm._s(_vm.fee.identityEmail)+"\n    ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }