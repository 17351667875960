<template>
  <list-grouped
    class="fees-list"
    :list="list"
    :list-total-count="0"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="FEES_PAGE_LIMIT"
    :load-more="loadMore"
    :reset-on-change="template"
    :head-component="FeesListHead"
    :head-component-props="{ template }"
    :item-component="FeeItem"
    :item-component-props="{ template }"
    :item-skeleton-component="FeeItemSkeleton"
    @update-list-ask="$emit('update-list-ask')"
  >
    <div
      class="fees-list__empty"
      slot="no-list-msg"
    >
      <p class="fees-list__empty-msg">
        {{ $t('NO_ITEMS_MSG') }}
      </p>

      <ui-button
        v-if="template === FEES_LIST_TEMPLATES.general"
        class="fees-list__empty-btn"
        @click="$emit('create-fee-click')"
      >
        <span>{{ $t('CREATE_GENERAL_FEE_BTN') }}</span>

        <ui-icon
          class="fees-list__empty-btn-icon"
          icon="plus"
        />
      </ui-button>
    </div>
  </list-grouped>
</template>

<script>
import { UiButton, UiIcon } from '@shelf.network/ui-kit'
import ListGrouped from 'Common/ListGrouped'

import FeeItem from './FeeItem'
import FeeItemSkeleton from './FeeItemSkeleton'
import FeesListHead from './FeesListHead'

import { mapGetters } from 'vuex'
import { feesGetters } from '../store/types'

import { FEES_LIST_TEMPLATES, FEES_PAGE_LIMIT } from '../constants'
import { validateArrayOf } from 'Models/modelUtils'
import { TransportationFee } from 'Models/TransportationFee'

export default {
  name: 'fees-list',
  components: {
    UiButton,
    UiIcon,
    ListGrouped
  },

  props: {
    list: {
      type: Array,
      required: true,
      validator: validateArrayOf(TransportationFee),
    },

    loadMore: {
      type: Function,
      required: true,
    },

    template: {
      type: String,
      default: FEES_LIST_TEMPLATES.general,
      validator (value) {
        return Object.values(FEES_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      FeeItem,
      FeeItemSkeleton,
      FeesListHead,
      FEES_PAGE_LIMIT,
      FEES_LIST_TEMPLATES,
    }
  },

  computed: {
    ...mapGetters('ui/fees', {
      isLoading: feesGetters.IS_LOADING,
      isNextLoading: feesGetters.IS_NEXT_LOADING,
    }),
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/fees.scss";

.fees-list {
  --list-padding-side: 3em;

  /deep/ &__empty-btn {
    margin-top: 1em;

    .ui-button__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
    }

    &-icon {
      display: block;
      margin-left: 1em;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "NO_ITEMS_MSG": "No items here yet",
    "CREATE_GENERAL_FEE_BTN": "Create General Fee"
  },
  "ka": {
    "NO_ITEMS_MSG": "სია ცარიელია",
    "CREATE_GENERAL_FEE_BTN": "საერთო ტარიფის შექმნა"
  },
  "ru": {
    "NO_ITEMS_MSG": "Нет комиссий для отображения",
    "CREATE_GENERAL_FEE_BTN": "Создать общую комиссию"
  },
  "uk": {
    "NO_ITEMS_MSG": "Нема комісій для відображення",
    "CREATE_GENERAL_FEE_BTN": "Створити загальну комісію"
  }
}
</i18n>
