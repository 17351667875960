<template>
  <div class="fees-list-head fees-cmn__row">
    <span class="fees-list-head__cell">
      <template v-if="template === FEES_LIST_TEMPLATES.users">
        {{ $t('USER_TH') }}
      </template>

      <template v-else>
        {{ $t('TYPE_TH') }}
      </template>
    </span>

    <span class="fees-list-head__cell">
      {{ $t('DESTINATION_TH') }}
    </span>

    <span
      v-for="port in departurePorts"
      :key="port.id"
      class="fees-list-head__cell"
    >
      {{ $t('DEPARTURE_PORT_TH', {
        region: port.name.toUpperCase(),
        state: port.id,
      }) }}
    </span>

    <span class="fees-list-head__cell">&nbsp;</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { feesGetters } from '../store/types'
import { FEES_LIST_TEMPLATES } from '../constants'

export default {
  name: 'fees-list-head',

  props: {
    template: {
      type: String,
      default: FEES_LIST_TEMPLATES.general,
      validator (value) {
        return Object.values(FEES_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      FEES_LIST_TEMPLATES,
    }
  },

  computed: {
    ...mapGetters('ui/fees', {
      departurePorts: feesGetters.DEPARTURE_PORTS
    })
  }
}
</script>

<style lang="scss" scoped>
@import "../styles/fees.scss";

.fees-list-head {
  padding: 2em 3em 0;

  &__cell {
    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "USER_TH": "USER",
    "TYPE_TH": "TYPE",
    "DESTINATION_TH": "DESTINATION",
    "DEPARTURE_PORT_TH": "{region} ({state})"
  },
  "ka": {
    "USER_TH": "ᲛᲝᲛᲮᲛᲐᲠᲔᲑᲔᲚᲘ",
    "TYPE_TH": "ᲢᲘᲞᲘ",
    "DESTINATION_TH": "ᲓᲐᲜᲘᲨᲜᲣᲚᲔᲑᲐ",
    "DEPARTURE_PORT_TH": "{region} ({state})"
  },
  "ru": {
    "USER_TH": "ПОЛЬЗОВАТЕЛЬ",
    "TYPE_TH": "ТИП",
    "DESTINATION_TH": "ПРИБЫТИЕ",
    "DEPARTURE_PORT_TH": "{region} ({state})"
  },
  "uk": {
    "USER_TH": "КОРИСТУВАЧ",
    "TYPE_TH": "ТИП",
    "DESTINATION_TH": "ПРИБУТТЯ",
    "DEPARTURE_PORT_TH": "{region} ({state})"
  }
}
</i18n>
