<template>
  <ui-portal>
    <ui-modal
      class="fee-modal"
      :title="fee
        ? $t('UPDATE_FEE_TITLE', { level: translatedFeeLevel })
        : $t('NEW_FEE_TITLE')
      "
      :show-close-btn="!isSubmitting"
      @close="close"
    >
      <form
        class="fee-modal__form"
        :class="{ 'fee-modal__form_loading': isSubmitting }"
        @submit.prevent="updateFees"
      >
        <div
          v-if="template === FEES_LIST_TEMPLATES.users"
          class="fee-modal__field"
        >
          <span class="fee-modal__field-lbl">
            {{ $t('USER_LBL') }}
          </span>

          <dealer-select
            v-model="form.identityId"
            class="fee-modal__field-input"
            :is-error="Boolean(errors.identityId)"
            :is-disabled="Boolean(fee)"
            @input="errors.identityId = ''"
          >
            <span slot="error">
              {{ errors.identityId }}
            </span>
          </dealer-select>
        </div>

        <div class="fee-modal__field">
          <span class="fee-modal__field-lbl">
            {{ $t('DESTINATION_LBL') }}
          </span>

          <ui-select
            v-model="form.destinationCountry"
            class="fee-modal__field-input"
            :options="destinationOptions"
            :placeholder="$t('DESTINATION_PH')"
            fill="frame"
          />
        </div>

        <p class="fee-modal__subtitle">
          {{ $t('CONTAINER_FEES_SUBTITLE') }}
        </p>

        <div class="fee-modal__fees">
          <div
            v-for="item in form.fees[form.destinationCountry]"
            :key="item.departurePort"
            class="fee-modal__field"
          >
            <span class="fee-modal__field-lbl">
              {{ $t('DEPARTURE_PORT_LBL', {
                region: $et('REGION', item.departurePort),
                state: item.departurePort,
              }) }}
            </span>

            <ui-text
              v-model="item.amount"
              class="fee-modal__field-input"
              :placeholder="$t('CONTAINER_FEE_PH')"
              type="number"
              min="0"
              max="10000000"
            >
              <span slot="dock-left">
                {{ feeCurrencySymbol }}
              </span>
            </ui-text>
          </div>
        </div>

        <div class="fee-modal__buttons">
          <ui-button
            type="submit"
            class="fee-modal__btn"
          >
            <template v-if="fee">
              {{ $t('UPDATE_FEE_BTN') }}
            </template>

            <template v-else>
              {{ $t('CREATE_FEE_BTN') }}
            </template>
          </ui-button>

          <ui-button
            @click="close"
            type="button"
            class="fee-modal__btn"
            look="secondary"
            fill="frame-hover"
          >
            {{ $t('CANCEL_BTN') }}
          </ui-button>
        </div>
      </form>

      <ui-spinner
        class="fee-modal__loader"
        overlay
        type="pills"
        :show="isSubmitting"
      />
    </ui-modal>
  </ui-portal>
</template>

<script>
import {
  UiButton,
  UiText,
  UiSpinner,
  UiModal,
  UiPortal,
  UiSelect
} from '@shelf.network/ui-kit'
import DealerSelect from 'Common/DealerSelect'

import { jsvLocalizeError } from 'Utils/jsvLocalizeError'
import { scrollToErrorInModal } from 'Utils/scrollTo'

import { mapGetters, mapActions } from 'vuex'
import { feesGetters, feesActions } from '../store/types'

import {
  DEFAULT_FEE_CURRENCY,
  DESTINATION_COUNTRIES,
  DESTINATION_PORTS,
  FEES_LIST_TEMPLATES
} from '../constants'

import { TransportationFee } from 'Models/TransportationFee'
import { currencySymbols } from 'Utils/currencySymbols'

import { formatLocation } from 'Utils/formatLocation'
import { showError, showSuccess } from 'Utils/notifications'

export default {
  name: 'fee-modal',
  components: {
    UiButton,
    UiText,
    UiSpinner,
    UiModal,
    UiPortal,
    UiSelect,
    DealerSelect
  },

  props: {
    fee: { type: TransportationFee, default: null },
    template: { type: String, default: FEES_LIST_TEMPLATES.users }
  },

  data () {
    return {
      form: {
        destinationCountry: DESTINATION_COUNTRIES.GE,
        identityId: '',
        fees: {},
      },
      errors: {
        identityId: '',
      },

      isSubmitting: false,

      FEES_LIST_TEMPLATES
    }
  },

  computed: {
    ...mapGetters('ui/fees', {
      departurePorts: feesGetters.DEPARTURE_PORTS
    }),

    defaultFees () {
      return Object.values(DESTINATION_COUNTRIES)
        .reduce((acc, country) => ({
          ...acc,
          [country]: this.departurePorts.map(port => ({
            departurePort: port.id,
            amount: ''
          }))
        }), {})
    },

    translatedFeeLevel () {
      return this.template === FEES_LIST_TEMPLATES.general
        ? this.$t('GENERAL_FEE_LEVEL_TXT')
        : this.$t('USER_FEE_LEVEL_TXT')
    },

    destinationOptions () {
      return Object.values(DESTINATION_COUNTRIES)
        .map(value => ({
          value,
          label: formatLocation({
            country: value,
            city: DESTINATION_PORTS[value]
          })
        }))
    },

    feeCurrencySymbol () {
      return currencySymbols[DEFAULT_FEE_CURRENCY]
    }
  },

  created () {
    this.form.fees = this.defaultFees

    if (this.fee) {
      this.form.identityId = this.fee.identityId
      this.fee.containerFees.forEach(fee => {
        const countryFees = this.form.fees[fee.destinationCountry]
        const feeIndex = countryFees
          .findIndex(item => item.departurePort === fee.departurePort)
        if (feeIndex === -1) return

        countryFees.splice(feeIndex, 1, fee)
      })
    }
  },

  methods: {
    ...mapActions('ui/fees', {
      updateTransportationFees: feesActions.UPDATE_TRANSPORTATION_FEES,
      setSelectedItemId: feesActions.SET_SELECTED_ITEM_ID
    }),

    async updateFees () {
      if (!this.validateForm()) {
        scrollToErrorInModal()
        return
      }

      this.isSubmitting = true
      try {
        await this.updateTransportationFees({
          identityId: this.form.identityId,
          fees: Object.entries(this.form.fees)
            .reduce((acc, [country, fees]) => ([
              ...acc,
              ...fees
                .filter(fee => Number(fee.amount))
                .map(({ departurePort, amount }, i) => ({
                  id: `${country}:${i}`,
                  amount: Number(amount).toFixed(2),
                  departurePort,
                  currency: DEFAULT_FEE_CURRENCY,
                  destinationCountry: country,
                  destinationPort: DESTINATION_PORTS[country],
                }))
            ]), [])
        })

        showSuccess(this.$t('UPDATE_FEES_SUCCESS_MSG'))
        this.$emit('fee-updated')

        this.close()
      } catch (e) {
        console.error(e)
        showError(this.$t('UPDATE_FEES_ERROR_MSG'))
      }
      this.isSubmitting = false
    },

    validateForm () {
      const isIdentityValid = this.fee ||
        this.form.identityId ||
        this.template === FEES_LIST_TEMPLATES.general

      this.errors.identityId = isIdentityValid
        ? ''
        : this.$t('USER_REQUIRED_ERROR')

      return Object.values(this.errors).every(val => !val)
    },

    close () {
      this.setSelectedItemId('')
      this.$emit('close')
    },

    jsvLocalizeError,
  },
}
</script>

<style scoped lang="scss">
.fee-modal {
  &__form {
    max-width: 100%;
    width: 36em;
    padding: 0.5em;

    &_loading {
      filter: blur(0.2em);
    }
  }

  &__field {
    margin-bottom: 2em;

    &-input {
      margin-top: 0.4em;

      /deep/ .ui-text {
        &__input {
          padding-left: 2.8em;
        }
      }
    }

    &-ico {
      color: $color-ui-secondary;
      font-size: 1.3em;
      vertical-align: middle;
    }
  }

  &__subtitle {
    margin-top: 2em;
    font-size: 1.3em;
    display: flex;
  }

  &__fees {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.6em;
    margin-top: 1em;
    padding-top: 1.6em;
    border-top: 1px solid $color-light-grey;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    padding-top: 2em;
    border-top: 1px solid $color-light-grey;

    @include respond-below(sm) {
      flex-direction: column;
    }
  }

  &__btn {
    min-width: 15em;

    @include respond-below(sm) {
      min-width: 0;
      margin: 1em 0;
    }
  }

  & /deep/ .modal__content {
    max-width: 40em;

    @include respond-below(sm) {
      max-width: unset;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "NEW_FEE_TITLE": "New fee",
    "UPDATE_FEE_TITLE": "Edit fee ({level})",
    "GENERAL_FEE_LEVEL_TXT": "General",
    "USER_FEE_LEVEL_TXT": "User",
    "USER_LBL": "User:",
    "USER_REQUIRED_ERROR": "Please, select user",
    "DESTINATION_LBL": "Destination:",
    "DESTINATION_PH": "Select port",
    "CONTAINER_FEES_SUBTITLE": "Container Fees",
    "DEPARTURE_PORT_LBL": "{region} ({state})",
    "CONTAINER_FEE_PH": "Fee amount",
    "CREATE_FEE_BTN": "Create fee",
    "UPDATE_FEE_BTN": "Update fee",
    "CANCEL_BTN": "Cancel",
    "UPDATE_FEES_SUCCESS_MSG": "Fees were successfully updated.",
    "UPDATE_FEES_ERROR_MSG": "Cannot update fees. Please try again later or contact the system owner."
  },
  "ka": {
    "NEW_FEE_TITLE": "ახალი ტარიფი",
    "UPDATE_FEE_TITLE": "ტარიფის ცვლილება ({level})",
    "GENERAL_FEE_LEVEL_TXT": "ზოგადი",
    "USER_FEE_LEVEL_TXT": "მომხმარებელი",
    "USER_LBL": "მომხმარებელი:",
    "USER_REQUIRED_ERROR": "აირჩიე მომხმარებელი",
    "DESTINATION_LBL": "დანიშნულება:",
    "DESTINATION_PH": "აირჩიე პორტი",
    "CONTAINER_FEES_SUBTITLE": "კონტეინერი",
    "DEPARTURE_PORT_LBL": "{region} ({state})",
    "CONTAINER_FEE_PH": "ტარიფი",
    "CREATE_FEE_BTN": "ტარიფის შექმნა",
    "UPDATE_FEE_BTN": "ტარიფის განახლება",
    "CANCEL_BTN": "უარყოფა",
    "UPDATE_FEES_SUCCESS_MSG": "ტარიფები წარმატებით განახლდა.",
    "UPDATE_FEES_ERROR_MSG": "ტარიფი ვერ განახლდა. კიდევ სცადეთ, ან დაუკავშირდით ადმინისტრატორს."
  },
  "ru": {
    "NEW_FEE_TITLE": "Новая комиссия",
    "UPDATE_FEE_TITLE": "Изменить комиссию ({level})",
    "GENERAL_FEE_LEVEL_TXT": "Общая",
    "USER_FEE_LEVEL_TXT": "Пользователь",
    "USER_LBL": "Пользователь:",
    "USER_REQUIRED_ERROR": "Пожалуйста, выберите пользователя",
    "DESTINATION_LBL": "Прибытие:",
    "DESTINATION_PH": "Выберите порт",
    "CONTAINER_FEES_SUBTITLE": "Комиссии контейнеров",
    "DEPARTURE_PORT_LBL": "{region} ({state})",
    "CONTAINER_FEE_PH": "Сумма комиссии",
    "CREATE_FEE_BTN": "Создать комиссию",
    "UPDATE_FEE_BTN": "Сохранить комиссию",
    "CANCEL_BTN": "Отмена",
    "UPDATE_FEES_SUCCESS_MSG": "Комиссии были успешно сохранены.",
    "UPDATE_FEES_ERROR_MSG": "Не удалось сохранить комиссии. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "NEW_FEE_TITLE": "Нова комісія",
    "UPDATE_FEE_TITLE": "Змінити комісію ({level})",
    "GENERAL_FEE_LEVEL_TXT": "Загальна",
    "USER_FEE_LEVEL_TXT": "Користувач",
    "USER_LBL": "Користувач:",
    "USER_REQUIRED_ERROR": "Будь ласка, виберіть користувача",
    "DESTINATION_LBL": "Прибуття:",
    "DESTINATION_PH": "Виберіть порт",
    "CONTAINER_FEES_SUBTITLE": "Комісії контейнерів",
    "DEPARTURE_PORT_LBL": "{region} ({state})",
    "CONTAINER_FEE_PH": "Сума комісії",
    "CREATE_FEE_BTN": "Створити комісію",
    "UPDATE_FEE_BTN": "Зберегти комісію",
    "CANCEL_BTN": "Відміна",
    "UPDATE_FEES_SUCCESS_MSG": "Комісії було успішно збережено.",
    "UPDATE_FEES_ERROR_MSG": "Не вдалось зберегти комісії. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
