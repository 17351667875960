<template>
  <div class="fee-user-cell cells-cmn__cell cells-cmn__btn">
    <lazy-ava-img
      class="cells-cmn__ava"
      :title="fee.identityFullName"
      :src="fee.identityAvatarLink"
    />

    <component
      :is="$can($USER_CLAIMS.EXTENSION_DEALERS) ? 'router-link' : 'div'"
      :to="{
        name: 'extension.copart.dealers.entry',
        params: { dealerId: fee.identityId }
      }"
      class="cells-cmn__str cells-cmn__str_nb"
    >
      <p
        class="cells-cmn__str cells-cmn__str_nb"
        :title="fee.identityFullName"
      >
        {{ fee.identityFullName }}
      </p>

      <p
        :class="[
          'fee-user-cell__email',
          'cells-cmn__str',
          'cells-cmn__str_nb',
          'cells-cmn__str_sec'
        ]"
        :title="fee.identityEmail"
      >
        {{ fee.identityEmail }}
      </p>
    </component>
  </div>
</template>

<script>
import LazyAvaImg from 'Common/LazyAvaImg'
import { TransportationFee } from 'Models/TransportationFee'

export default {
  name: 'fee-user-cell',
  components: { LazyAvaImg },

  props: {
    fee: {
      type: TransportationFee,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.fee-user-cell {
  text-decoration: none;

  &__email {
    font-size: 0.8em;
  }
}
</style>
